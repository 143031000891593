@import "styles/variables.scss";
@import 'styles/mixsins';

.Root {
  width: 100%;
  position: relative;
}

.Textarea {
  @include FormText;
  resize: none;
}

.TextareaError {
  border-color: $red-primary;

  &:focus, &:focus-visible {
    border: 1px solid $red-primary;
  }
}

.Buttons {
  position: absolute;
  right: 26px;
  bottom: 12px;
}

.Error {
  @include formError;
}
