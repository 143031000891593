@import "styles/variables.scss";
.statistic {
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  margin-bottom: 32px;
  flex: 1;
  // gap:29px;
  padding: 27px 32px;

  @media screen and (max-width: 1000px) {
    gap: 15px;
  }

  @media screen and (max-width: 834px) {
    flex-direction: column;
  }

  .statistic__winner {
    display: flex;
    justify-content: space-between;
    gap: 29px;
    border-right: 1px solid #eeeeee;

    @media screen and (max-width: 1000px) {
      gap: 10px;
    }

    @media screen and (max-width: 834px) {
      justify-content: unset;
      border: unset;
      padding-bottom: 29px;
      border-bottom: 1px solid #eeeeee;
    }

    .winner__block_text {
      display: flex;
      flex-direction: column;
      gap: 3px;
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      line-height: 18px;
      color: #666666;
      margin-right: 27px;

      @media screen and (max-width: 1000px) {
        margin-right: 20px;
      }

      @media screen and (max-width: 834px) {
        margin-right: 0;
      }

      .block_text_rating {
        display: flex;
        align-items: center;
        gap: 22px;

        @media screen and (max-width: 1000px) {
          gap: 10px;
        }
      }
    }
  }

  .statistic__defeats {
    display: flex;
    justify-content: space-between;
    gap: 29px;
    border-right: 1px solid #eeeeee;

    @media screen and (max-width: 1000px) {
      gap: 10px;
    }

    @media screen and (max-width: 834px) {
      justify-content: unset;
      border: unset;
      padding-bottom: 29px;
      border-bottom: 1px solid #eeeeee;
    }

    .defeats__block_text {
      display: flex;
      flex-direction: column;
      gap: 3px;
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      line-height: 18px;
      color: #666666;
      margin-right: 27px;

      @media screen and (max-width: 1000px) {
        margin-right: 20px;
      }

      @media screen and (max-width: 834px) {
        margin-right: 0;
      }

      .block_text_rating {
        display: flex;
        align-items: center;
        gap: 22px;

        @media screen and (max-width: 1000px) {
          gap: 10px;
        }
      }
    }
  }

  .statistic__draw {
    display: flex;
    justify-content: space-between;
    gap: 29px;
    // border-right: 1px solid #EEEEEE;

    @media screen and (max-width: 1000px) {
      gap: 20px;
    }

    @media screen and (max-width: 834px) {
      justify-content: unset;
      border: unset;
    }
  }
}

.title {
  display: flex;
  flex-direction: column;
  align-items: center;

  @media screen and (max-width: 834px) {
    min-width: 80px;
  }
  span {
    font-style: normal;
    font-weight: 600;
    font-size: 28px;
    line-height: 130%;
    color: #1e1e1e;
  }

  span:last-child {
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    line-height: 150%;
    color: #999999;
  }
}
