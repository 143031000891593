@import "styles/variables.scss";
.Root {
  position: relative;
  height: 120px;
  width: 120px;

  svg {
    position: absolute;
    top: -5px;
    left: 30px;
  }
}
