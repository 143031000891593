@import "styles/variables.scss";
.Description {
  margin-bottom: 24px;
}

.Form {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.SubmitButton {
  margin-top: 16px;
  height: 48px;
}

.SignInButton {
  display: flex;
  align-items: center;
  cursor: pointer;
}

.SuccessTitle {
  display: flex;
  gap: 1rem;
}
