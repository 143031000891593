@import "styles/variables.scss";
.Email {
  margin-bottom: 1rem;
}

.Google {
  width: 100%;
  margin-top: 10px;
  height: 4rem;
}

.Password {
  margin-bottom: 0.5rem;
}

.ForgotPassword {
  font-size: 0.75rem;
  color: $grey-primary;
  margin-bottom: 2rem;
}

.LoginButton {
  margin-bottom: 2rem;
}

.Button {
  height: 4rem;
}

.Registration {
  padding-top: 2rem;
  display: flex;
  align-items: center;
}

.RegistrationButton {
  font-weight: 500;
  display: block;
  text-transform: uppercase;
  line-height: 16.5px;
  height: unset;
  padding: 4px 10px;
  font-size: 11px;
  margin: 0 auto;
}
