@import "styles/variables.scss";
.Root {
  padding: 24px 32px 0;
  font-weight: 600;
  font-size: 20px;
  line-height: 150%;
  display: flex;
  align-items: center;

  @media (max-width: $mobile-size) {
    padding: 24px 16px 0;
  }
}
