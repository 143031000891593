@import "styles/variables.scss";
.ChildrenWrapper p {
  padding: 12px 26px 0;
  color: var(--font-color-primary);

  span {
    font-weight: 600;
  }
}

.Button {
  width: max-content;
  min-width: 110px;
}
