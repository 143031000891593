@import "styles/variables.scss";
.info {
  margin-bottom: 24px;
  display: flex;
  flex-direction: column;

  .info__name {
    font-style: normal;
    font-weight: 600;
    font-size: 32px;
    line-height: 130%;
    font-feature-settings: 'pnum' on, 'lnum' on;
    color: #1e1e1e;
    margin-bottom: 1px;

    @media screen and (max-width: 600px) {
      font-size: 28px;
    }
  }
  .info__lastName {
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    line-height: 150%;
    color: #999999;
  }

  .info__main {
    display: flex;
    margin-top: 32px;
    margin-bottom: 32px;

    .main__foto {
      width: 163px;
      height: 183px;
      background: #fbfbfb;
      border: 1px solid #e4e4e4;
      box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.06);
      border-radius: 10px;
      padding: 11px 14px;
      box-sizing: border-box;
      margin-right: 32px;
      position: relative;
      display: flex;
      align-items: center;
      flex-direction: column;

      @media screen and (max-width: 600px) {
        margin-right: 24px;
      }

      .foto__img {
        margin-bottom: 8px;
        position: relative;
        background-position: center;
        background-repeat: no-repeat;
        background-size: contain;
        width: 141px;
        height: 88px;
        margin-top: 10px;

        &::after {
          content: '';
          width: 161px;
          height: 1px;
          background: linear-gradient(
            270deg,
            rgba(0, 0, 0, 0) 0%,
            #000000 52.6%,
            rgba(0, 0, 0, 0) 100%
          );
          opacity: 0.4;
          position: absolute;
          bottom: 0;
          left: calc(50% - 80px);
        }
      }
      .foto__flag {
        position: absolute;
        top: 8px;
        right: 12px;
        width: 16px;
      }

      .foto_rating {
        position: absolute;
        top: 8px;
        left: 12px;
        font-style: normal;
        font-weight: 400;
        font-size: 10px;
        line-height: 150%;
        color: #666666;
        opacity: 0.8;
      }

      .foto__name {
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 150%;
        color: #1e1e1e;
      }

      .foto__fights {
        font-style: normal;
        font-weight: 400;
        font-size: 13px;
        line-height: 150%;
        color: #666666;
      }
    }

    .main__generals {
      display: flex;
      flex-direction: column;
      border-bottom: 1px solid #eeeeee;
      width: 100%;

      .generals__rating {
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 150%;
        color: #1e1e1e;
        margin-bottom: 16px;
        display: flex;
        align-items: center;
        gap: 8px;

        img {
          cursor: pointer;
          opacity: 0.8;
        }

        @media screen and (max-width: 685px) {
          gap: 6px;
          padding-bottom: 20px;
          border-bottom: 1px solid #eeeeee;
        }
      }

      .generals__info {
        display: flex;
        gap: 24px;

        @media screen and (max-width: 685px) {
          gap: 16px;
        }

        .generals__info_name {
          display: flex;
          flex-direction: column;
          font-style: normal;
          font-weight: 400;
          font-size: 16px;
          line-height: 28px;
          color: #666666;

          @media screen and (max-width: 600px) {
            font-size: 14px;
          }

          span:nth-child(1) {
            @media screen and (max-width: 600px) {
              display: none;
            }
          }
        }

        .generals__info_data {
          display: flex;
          flex-direction: column;
          font-style: normal;
          font-weight: 500;
          font-size: 16px;
          line-height: 28px;
          color: #1e1e1e;

          @media screen and (max-width: 600px) {
            font-size: 14px;
            text-align: end;
          }

          .data__block {
            span:last-child {
              color: rgba(102, 102, 102, 1);
              font-weight: 400;

              @media screen and (max-width: 600px) {
                display: none;
              }
            }

            &:nth-child(1) {
              @media screen and (max-width: 600px) {
                display: none;
              }
            }
          }
        }
      }
    }
  }

  .info__style {
    display: flex;
    gap: 24px;

    @media screen and (max-width: 600px) {
      flex-direction: column;
    }

    .style__category {
      display: flex;
      flex-direction: column;
      gap: 3px;

      .style__category_weight {
        display: flex;

        span:first-child {
          font-style: normal;
          font-weight: 500;
          font-size: 14px;
          line-height: 150%;
          color: #1e1e1e;
        }

        span:last-child {
          font-style: normal;
          font-weight: 400;
          font-size: 14px;
          line-height: 150%;
          color: #666666;
          margin-left: 4px;
        }
      }

      span:first-child {
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 150%;
        color: #999999;
      }

      span:last-child {
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 150%;
        color: #1e1e1e;
      }
    }
  }
}
