@import "styles/variables.scss";
.SignUpRootModal{
  overflow-x: hidden;
}

.Group {
  margin-bottom: 1.5rem;
}

.Google {
  width: 100%;
  margin-top: 10px;
  height: 4rem;
}

.Title {
  margin-bottom: 0.375rem;
  color: var(--font-color-primary);
}

.Button {
  height: 4rem;
}

.Agreement {
  font-size: 12px;
  line-height: 130%;

  a {
    text-decoration: none;
  }
}

@media (max-width: 768px) {
  .Group {
    margin-bottom: 1.045rem;
  }

  .Google {
    width: 100%;
    height: 2.85rem;
  }

  .Title {
    font-size: 12.92px;
    margin-bottom: 0.3028125rem;
  }

  .NickExampleText {
    font-size: 12.92px;
  }

  .Input {
    padding: 0.5985rem 0.83125rem;
    height: 2.4225rem;
    font-size: 13.6px;
  }

  .Button {
    height: 2.85rem;
  }

  .SignIn {
    height: 32.3px;
  }

  .Agreement {
    font-size: 9.975px;
    line-height: 123.5%;

    a {
      text-decoration: none;
    }
  }

  .PromoBanner {
    display: flex;
    justify-content: center;
    text-align: center;
    margin-bottom: 10px;
  }

  .PromoText {
    font-size: 15px !important;
  }
}