@import "styles/variables.scss";
.Root {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: $grey-primary-6;
  overflow: hidden;
  flex-shrink: 0;

  svg {
    fill: $grey-primary;
  }
  
  &.Active {
    background-color: $blue-click;
    
    svg {
      fill: $white-color;
    }
  }
}

@mixin avatarParams($size, $radius, $svgSize) {
  width: $size;
  height: $size;
  border-radius: $radius;

  img {
    width: $size;
    height: $size;
  }

  svg {
    width: $svgSize;
    height: $svgSize;
  }
}

.L {
  @include avatarParams(104px, 10px, 26px);
}

.M {
  @include avatarParams(80px, 10px, 24px);
}

.S {
  @include avatarParams(40px, 6px, 16px);
}

.XS {
  @include avatarParams(22px, 4px, 12px);
}

.Dark {
  background-color: $grey-primary-7;

  svg {
    fill: $grey-primary-5;
  }
}
