@import "styles/variables.scss";
.Root {
  position: relative;
  font-size: 18px;

  .ItemActive {
    padding-bottom: 10px;
    
    @media (max-width: $mobile-size) {
      padding-bottom: 5px;
    }
  }
}

.RootMedium {
  composes: Root;

  font-size: 16px;
  font-weight: 400;
  line-height: 150%;
  color: $grey-primary-7;

  .ItemActive {
    padding-bottom: 2px;
  }
}

.RootMedium {
  composes: Root;
  position: relative;
  font-size: 16px;

  .ItemActive {
    padding-bottom: 2px;
  }
}

.RootSmall {
  composes: Root;

  font-size: 13px;
  line-height: 150%;

  .ItemActive {
    padding-bottom: 2px;
  }
}

.List {
  display: flex;
  align-items: flex-start;
}

.Item {
  color: var(--font-color-secondary);
  margin-right: 14px;
  white-space: nowrap;
  cursor: pointer;
  display: flex;
  gap: 8px;
  transition: $transition;

  &:last-child {
    margin-right: 0;
  }

  &:hover {
    transition: $transition;
    color: var(--font-color-primary);
  }
}

.ItemDisabled {
  cursor: not-allowed;
  color: $grey-primary-7;

  &:hover {
    color: $grey-primary-7;
  }
}

.ItemActive {
  color: var(--font-color-primary);
  border-bottom: 1px solid var(--border-color-default);
  font-weight: 500;
  padding-bottom: 0.16em;
}

.Count {
  margin-top: 3px;
  color: $blue-primary;
  font-size: 0.7777em;
}
