@import "styles/variables.scss";
@font-face {
  font-family: 'Golos';
  src: url('/fonts/golos-black.woff2') format('woff2'),
    url('/fonts/golos-black.woff') format('woff');
  font-weight: 900;
  font-style: normal;
}
@font-face {
  font-family: 'Golos';
  src: url('/fonts/golos-bold.woff2') format('woff2'),
    url('/fonts/golos-bold.woff') format('woff');
  font-weight: 700;
  font-style: normal;
}
@font-face {
  font-family: 'Golos';
  src: url('/fonts/golos-demi-bold.woff2') format('woff2'),
    url('/fonts/golos-demi-bold.woff') format('woff');
  font-weight: 600;
  font-style: normal;
}
@font-face {
  font-family: 'Golos';
  src: url('/fonts/golos-medium.woff2') format('woff2'),
    url('/fonts/golos-medium.woff') format('woff');
  font-weight: 500;
  font-style: normal;
}
@font-face {
  font-family: 'Golos';
  src: url('/fonts/golos-regular.woff2') format('woff2'),
    url('/fonts/golos-regular.woff') format('woff');
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: 'Golos';
  src: url('/fonts/golos-vf.woff2') format('woff2'),
    url('/fonts/golos-vf.woff') format('woff');
  font-weight: 200;
  font-style: normal;
}

html,
body {
  padding: 0;
  margin: 0;
  -webkit-font-smoothing: antialiased;
  font-family: Golos, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto,
    Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
  color: $black-primary;
  background-color: $background-color;
  font-size: 16px;
}

#__next {
  position: relative;
  min-height: 100vh;
}

a.noLink {
  color: inherit;
  text-decoration: none;
}

* {
  box-sizing: border-box;
  font-family: Golos, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto,
    Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
}

ul {
  margin: 0;
  padding: 0;
  list-style: none;
}

button {
  font-size: 16px;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  padding: 0;
  margin: 0;
}

h1 {
  font-weight: 600;
  font-size: 32px;
  line-height: 130%;

  @media (max-width: $tablet-size) {
    font-size: 22px;
    line-height: 130%;
  }
}

a {
  color: $blue-primary;
  text-decoration: underline;
  text-underline-offset: 3px;
}

:root {
  --swiper-theme-color: #1e1e1e;
}

.vs-content {
  max-width: 980px;
  margin: 40px auto;
}

.main {
  width: 100%;
  height: 100%;
  max-width: 980px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 40px;
  box-sizing: border-box;

  @media screen and (max-width: 685px) {
    padding: 40px 0;
  }

  .card {
    width: 100%;
    max-width: 980px;
    background: #ffffff;
    border-radius: 10px;
    padding: 24px 32px;
    box-sizing: border-box;

    @media screen and (max-width: 685px) {
      padding: 24px 16px;
    }
  }
}

.vs-loader {
  position: static;
  height: 100vh;
  width: 100vw;
  display: flex;
  align-items: center;
  justify-content: center;
}
