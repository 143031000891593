@import "styles/variables.scss";
.Root {
  width: 100%;
  padding: 32px;

  @media (max-width: $mobile-size) {
    padding: 16px 24px;
  }
}

.WithoutVertical {
  composes: Root;

  padding: 0 32px;

  @media (max-width: $mobile-size) {
    padding: 0 24px;
  }
}

.small {
  padding: 11px 18px 18px;
}

.large {
  padding: 24px 32px 32px;
}
