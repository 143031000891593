@import "styles/variables.scss";
.history {
  display: flex;
  flex-direction: column;
  padding: 24px 0 !important;
  overflow-x: scroll;

  &::-webkit-scrollbar {
    width: 0;
    height: 0;
  }

  .history__title {
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 150%;
    color: #1e1e1e;
    margin-bottom: 18px;
    margin-left: 32px;
  }

  .history__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 110%;
    color: #999999;
    // gap: 36px;
    margin-bottom: 14px;
    padding: 14px 32px;
    box-sizing: border-box;
    width: 950px;

    .header__result {
      min-width: 77px;
      margin-right: 32px;
    }

    .header__rival {
      min-width: 155px;
      margin-right: 32px;
    }

    .header__event {
      min-width: 268px;
      margin-right: 32px;

      // @media screen and (max-width:600px) {
      //     display: none;
      // }
    }
    .header__way {
      min-width: 116px;
      margin-right: 32px;
      // @media screen and (max-width:833px) {
      //     display: none;
      // }
    }
    .header__round {
      min-width: 47px;
      margin-right: 32px;
      // @media screen and (max-width:1024px) {
      //     display: none;
      // }
    }
    .header__pari {
      min-width: 70px;
      // margin-right: 32px;
      // @media screen and (max-width:1024px) {
      //     display: none;
      // }
    }
  }

  .history__item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    // gap:32px;
    // height: 72px;
    border-top: 1px solid #eeeeee;
    padding: 14px 32px;
    box-sizing: border-box;

    &:nth-child(2n) {
      background: #fbfbfb;
    }

    .item__winner {
      min-width: 77px;
      margin-right: 32px;

      .win {
        max-width: 53px;
        height: 18px;
        background: #35c244;
        border-radius: 4px;
        font-style: normal;
        font-weight: 500;
        font-size: 12px;
        line-height: 130%;
        color: #ffffff;
        display: flex;
        justify-content: center;
        align-items: center;
      }

      .loss {
        width: 77px;
        height: 18px;
        left: 502px;
        top: 1353px;
        background: #db2828;
        border: 1px solid #db2828;
        border-radius: 4px;
        font-style: normal;
        font-weight: 500;
        font-size: 12px;
        line-height: 130%;
        color: #ffffff;
        display: flex;
        justify-content: center;
        align-items: center;
      }

      .draw {
        width: 77px;
        height: 18px;
        left: 502px;
        top: 1353px;
        background: $toast-warning;
        border: 1px solid $toast-warning;
        border-radius: 4px;
        font-style: normal;
        font-weight: 500;
        font-size: 12px;
        line-height: 130%;
        color: #ffffff;
        display: flex;
        justify-content: center;
        align-items: center;
      }

      .nc {
        width: 25px;
        height: 18px;
        left: 502px;
        top: 1353px;
        background: $grey-primary;
        border: 1px solid $grey-primary;
        border-radius: 4px;
        font-style: normal;
        font-weight: 500;
        font-size: 12px;
        line-height: 130%;
        color: #ffffff;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }

    .item__rival {
      min-width: 155px;
      display: flex;
      gap: 10px;
      margin-right: 32px;
      .item__rival_logo {
        width: 40px;
        height: 40px;
        border: 1px solid #000000;
        border-radius: 40px;
        overflow: hidden;
        img {
          width: 100%;
        }
      }

      .rival__name {
        display: flex;
        flex-direction: column;

        .name {
          font-style: normal;
          font-weight: 500;
          font-size: 14px;
          line-height: 150%;
          color: #2873db;
        }
        .static {
          font-style: normal;
          font-weight: 400;
          font-size: 13px;
          line-height: 150%;
          color: #666666;
        }
      }
    }

    .item__event {
      min-width: 268px;
      display: flex;
      flex-direction: column;
      font-style: normal;
      font-weight: 400;
      font-size: 13px;
      line-height: 150%;
      color: #666666;
      margin-right: 32px;

      span:first-child {
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 150%;
        color: #2873db;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;

        a {
          &:hover {
            cursor: pointer;
          }
        }
      }

      // @media screen and (max-width:600px) {
      //     display: none;
      // }
    }
    .item__way {
      min-width: 116px;
      display: flex;
      flex-direction: column;
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 150%;
      color: #1e1e1e;
      margin-right: 32px;

      span:last-child {
        font-size: 13px;
        color: #666666;
      }
      // @media screen and (max-width:833px) {
      //     display: none;
      // }
    }
    .item__round {
      min-width: 47px;
      display: flex;
      flex-direction: column;
      display: flex;
      flex-direction: column;
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 150%;
      color: #1e1e1e;
      margin-right: 32px;

      span:last-child {
        font-size: 13px;
        color: #666666;
      }
      // @media screen and (max-width:1024px) {
      //     display: none;
      // }
    }
    .item__pari {
      min-width: 41px;
      margin-right: 32px;
      // @media screen and (max-width:1024px) {
      //     display: none;
      // }
    }
  }
}
