@import "styles/variables.scss";
.Root {
  display: none;
  position: fixed;
  background-color: rgba(0, 0, 0, 0.5);
  top: 0;
  left: 0;
  height: 100%;
  height: -webkit-fill-available;
  width: 100%;
  overflow: hidden;
  z-index: 100;
  align-items: flex-end;
  flex-direction: column;
}

.RootOpened {
  composes: Root;

  display: flex;

  @media (max-width: $mobile-size) {
    overflow-y: auto;
  }
}

.RootEnter {
  composes: RootOpened;

  background-color: rgba(0, 0, 0, 0);
  transition: background-color 0.3s ease-in-out;

  & .Panel {
    bottom: -80vh;
    transition: bottom 0.3s ease-in-out;
  }
}
.RootEnterActive {
  composes: RootOpened;

  background-color: rgba(0, 0, 0, 0.5);

  & .Panel {
    bottom: 0;
  }
}
.RootExit {
  composes: RootOpened;

  background-color: rgba(0, 0, 0, 0.5);
  transition: background-color 0.3s ease-in-out;

  & .Panel {
    bottom: 0;
    transition: bottom 0.3s ease-in-out;
  }
}
.RootExitActive {
  composes: RootOpened;

  background-color: rgba(0, 0, 0, 0);

  & .Panel {
    bottom: -80vh;
  }
}

.Close {
  width: 100%;
  flex-grow: 1;
}

.PanelClose {
  flex-shrink: 0;
  width: 100%;
  height: 24px;

  &::after {
    content: '';
    width: 38px;
    height: 4px;
    position: absolute;
    top: 7px;
    left: calc(50% - 19px);
    background: url(./top.svg) no-repeat center;
  }
}

.Panel {
  flex-shrink: 0;
  position: relative;
  width: 100%;
  height: auto;
  background: var(--background-color-card);
  box-shadow: 0px 1px 6px rgba(0, 0, 0, 0.01);
  border-radius: 10px 10px 0px 0px;
}
