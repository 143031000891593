@import "styles/variables.scss";
.Root {
  position: fixed;
  display: flex;
  top: 0;
  left: 0;
  height: 100dvh;
  width: 100%;
  z-index: 10000;
  justify-content: center;
  align-items: center;
  transition: all 0.3s ease-out;
  color: var(--font-color-primary);

  @media (max-width: 768px) {
    padding: 0 16px;
  }

  @media (max-width: $mobile-size) {
    padding: 0;
  }
}

.Overflow {
  position: absolute;
  top: 0;
  left: 0;
  background: $black-color;
  opacity: 0.7;
  height: 100%;
  width: 100%;
  z-index: 10001;
}

.NoOverflow {
  composes: Overflow;
  background: transparent;
}

.Panel {
  position: relative;
  background: var(--background-color-modal-panel);
  border-radius: 0.75rem;
  z-index: 10002;
  padding: 1.275rem 0 1.7rem;
  max-width: 400px;
  width: 100%;
  overflow-y: auto;
  max-height: 100dvh;

  @media (max-width: 768px) {
    width: calc(100% - 32px);
    max-height: 75dvh;
  }

  @media (max-width: $mobile-size) {
    width: 100% !important;
  }
}

.Close {
  cursor: pointer;
  width: 24px;
  height: 24px;

  svg {
    fill: $grey-primary-4;
  }
}

.BodyWrapped {
  overflow: hidden;
}

.TitleWrapper {
  margin-bottom: 1.5rem;
  padding: 0 2rem;
  gap: 8px;
}

.Title {
  font-weight: 600;
  color: var(--font-color-primary);
  font-size: 1.488rem;
}

.Content {
  padding: 0 2rem;
}

.Footer {
  margin-top: 1.275rem;
  padding: 1.275rem 1.7rem 0;
  border-top: 1px solid var(--border-color-secondary);
}

.PromoWrapper {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 0.5rem;
}

.PromoImage {
  filter: drop-shadow(0 0 10px rgba(248, 21, 87, 0.6));
}

.PromoText {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: $white-color;
  text-align: center;
}

.PromoText span {
  font-weight: bold;
  text-transform: uppercase;
  border-bottom: 2px solid white;
  padding-bottom: 1px;
  font-size: 15px;
}