@import "styles/variables.scss";
.Dots {
  font-weight: 600;
  font-size: 22px;
  color: $blue-primary;
}

.Button {
  padding: 0 8px;
  height: unset;
  font-size: 1rem;
  line-height: 1.5rem;
}
