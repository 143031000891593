@import "styles/variables.scss";
.Desktop {
  display: none;

  @media (max-width: $mobile-size) {
    display: block;
  }
}

.Mobile {
  display: block;

  @media (max-width: $mobile-size) {
    display: none;
  }
}
