@import "styles/variables.scss";
@import "styles/mixsins";

.Root {
  display: flex;
  align-items: center;
  column-gap: 4px;
  font-size: $font-size-m;
  line-height: $line-height-base;
  color: var(--font-color-primary);
  font-weight: 500;
  text-decoration: none;
  padding-top: 4px;
  padding-bottom: 10px;
  margin-bottom: 10px;
  cursor: pointer;
  transition: сolor $transition;
  @include border;
  @include linkActions;

  &:hover svg {
    transition: $transition;
    fill: $blue-hover;
  }

  &:active svg {
    fill: $blue-click;
  }
}

.NoBorder {
  border: none;
}

.NoClick {
  pointer-events: none;
}

.Arrow {
  transform: rotate(-90deg);
  height: 19px;
  width: auto;
  margin-top: 2px;
  transition: $transition;
}
