@import "styles/variables.scss";
.Root {
  position: relative;
  width: 100%;
  max-width: 190px;
  background: var(--background-color-orders-panel);
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.06);
  border: 1px solid $grey-primary-2;
  border-radius: 10px;
  padding: 14px 12px;

  display: flex;
  flex-direction: column;
  align-items: center;

  cursor: pointer;

  @media (max-width: $mobile-size) {
    max-width: 163px;
    height: auto;
    padding: 14px 12px 12px;
  }
}

.ActiveRoot {
  composes: Root;

  cursor: pointer;

  &:hover {
    background: $white-color;
  }
}

.DisabledRoot {
  background: var(--background-disabled-root);

  & .Image {
    //opacity: 0.5;
  }
}

.Selected,
.WinResult,
.LoseResult {
  position: relative;

  & .Name,
  & .Score,
  & .Coefficient,
  & .Rank,
  & .Win,
  & .Lose {
    color: $white-color;
  }

  & .Image {
    opacity: 1;
  }
}

.Selected {
  background-size: cover;
  @media (max-width: $mobile-size) {
    &::after {
      width: 12px;
      height: 12px;
      right: 12px;
      bottom: 12px;
    }
  }
}

.LeftHightlighted {
  box-shadow: inset 0px 0px 0px 2px $red-primary, inset 0px 0px 0px 4px white;
}

.CenterHightlighted {
  box-shadow: inset 0px 0px 0px 2px $purple-primary, inset 0px 0px 0px 4px white;
}

.RightHightlighted {
  box-shadow: inset 0px 0px 0px 2px $blue-primary, inset 0px 0px 0px 4px white;
}

.Confirmed {
  &::after {
    position: absolute;
    content: '';
    width: 16px;
    height: 16px;
    background: url(./mark.svg) no-repeat center;
    right: 16px;
    bottom: 16px;
    background-size: cover;
  }
}

.LeftSelected {
  composes: Selected;
  border: none;
  background: var(--background-color-opponents-left-card);
  background-size: cover;

  @media (max-width: $mobile-size) {
    &::after {
      width: 12px;
      height: 12px;
      right: 12px;
      bottom: 12px;
    }
  }
}

.CenterSelected {
  composes: Selected;
  border: none;
  background: var(--background-color-opponents-center-card);
  background-size: cover;

  @media (max-width: $mobile-size) {
    &::after {
      width: 12px;
      height: 12px;
      right: 12px;
      bottom: 12px;
    }
  }
}

.RightSelected {
  composes: Selected;
  border: none;
  background: var(--background-color-opponents-right-card);
  background-size: cover;

  @media (max-width: $mobile-size) {
    &::after {
      width: 12px;
      height: 12px;
      right: 12px;
      bottom: 12px;
    }
  }
}

.WinResult {
  composes: Selected;

  background: $win-color;
  background-size: cover;

  @media (max-width: $mobile-size) {
    &::after {
      width: 12px;
      height: 12px;
      right: 12px;
      bottom: 12px;
    }
  }
}

.LoseResult {
  composes: Selected;

  background: $grey-primary-7;
  background-size: cover;

  @media (max-width: $mobile-size) {
    &::after {
      width: 12px;
      height: 12px;
      right: 12px;
      bottom: 12px;
    }
  }
}

.Image {
  height: 59px;
  width: 100%;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  z-index: 1;
  position: relative;

  @media (max-width: $mobile-size) {
    height: 88px;
    min-height: 88px;
  }

  .Root[data-sport-type='FOOTBALL'] & {
    height: 62px;
    margin-bottom: 5px;

    @media (max-width: $mobile-size) {
      height: 62px;
      min-height: 62px;
      margin-bottom: 5px;
    }
  }
}

.ImageShadow {
  bottom: 0;
  position: absolute;
  content: '';
  left: 50%;
  transform: translateX(-50%);
  width: 115px;
  height: 1px;
  background: linear-gradient(
    270deg,
    rgba(165, 165, 165, 0) 0%,
    #bfbfbf 52.6%,
    rgba(165, 165, 165, 0) 100%
  );
  opacity: 0.8;

  @media (max-width: $mobile-size) {
    width: calc(100% + 24px);
  }
}

.ImageShadowActive {
  background: linear-gradient(
    270deg,
    rgba(0, 0, 0, 0) 0%,
    #000000 52.6%,
    rgba(0, 0, 0, 0) 100%
  );
}

.Name {
  margin-top: 8px;
  font-size: 14px;
  line-height: 150%;
  text-align: center;
}

.Score {
  font-size: 13px;
  line-height: 130%;
  color: $grey-primary-7;

  @media (max-width: $mobile-size) {
    font-size: 12px;
  }
}

.Coefficient {
  font-size: 13px;
  line-height: 150%;
  color: $blue-primary;
}

.Rank {
  position: absolute;
  top: 12px;
  left: 16px;
  font-size: 10px;
  line-height: 150%;
  color: $grey-primary-7;
  @media (max-width: $mobile-size) {
    top: 8px;
    left: 12px;
  }
}

.Flag {
  position: absolute;
  top: 12px;
  right: 16px;

  svg {
    height: 12px;
  }

  @media (max-width: $mobile-size) {
    top: 8px;
    right: 12px;
  }
}

.NameText {
  white-space: nowrap;
  overflow-x: hidden;
  text-overflow: ellipsis;
}


