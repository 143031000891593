@import "styles/variables.scss";
.rating {
  display: flex;

  .rating__block {
    width: 6px;
    height: 8px;
    border: 1px solid #cccccc;
    border-radius: 2px;
    margin: 0 1px;
    box-sizing: border-box;

    &.color {
      background: linear-gradient(270.49deg, #ff4f2e -31.51%, #ffc500 99.58%);
      border: unset;
    }

    &.grey {
      background: linear-gradient(90deg, #828282 0%, #666666 107.61%);
    }
  }
}
