@import "styles/variables.scss";
@import "styles/mixsins";

@mixin block-outline {
  color: var(--font-color-primary);
  outline: 1px solid $grey-primary;
  border-radius: 6px;
  margin: $news-block-margin 4px 2px 2px;
  position: relative;
}

@mixin block-outline-text($top, $left) {
  position: absolute;
  color: $grey-primary-7;
  font-size: $font-size-s-2;
  font-weight: 400;
  top: $top;
  left: $left;
}

#editor :global {
  .video-tool__caption {
    display: block !important;
    @include block-outline;
    padding: 28px 8px 8px 8px;
    width: unset;
    box-shadow: none;
    border: none;
    line-height: 1.6em;
    
    &::after {
      @include block-outline-text(0, 12px);
      content: var(--caption-video);
    }
  }

  .cdx-input.image-tool__caption {
    @include block-outline;
    padding: 28px 8px 8px 8px;
    width: unset;
    box-shadow: none;
    border: none;
    line-height: 1.6em;
    
    &::after {
      @include block-outline-text(0, 12px);
      content: var(--caption-image);
    }
  }

  .ce-paragraph {
    @include block-outline;
    padding: 28px 8px 8px 8px;

    &::after {
      @include block-outline-text(0, 12px);
      content: var(--paragraph);
    }
  }

  .cdx-list {
    @include block-outline;
    padding: 26px 8px 8px 30px;

    &::after {
      @include block-outline-text(4px, 12px);
      content: var(--list);
    }
  }

  .cdx-list__item {
    padding: 2px 0;
  }

  .ce-header {
    @include block-outline;
    padding: 28px 8px 8px 8px;

    &::after {
      @include block-outline-text(4px, 12px);
      content: var(--title);
    }
  }
}

#editor {
  font-size: $font-size-base;
  width: 100%;

  h2 {
    font-size: $font-size-xxl-2;
  }

  h3 {
    font-size: $font-size-xl;
  }

  h4 {
    font-size: $font-size-l;
  }

  @media (max-width: 800px) {
    padding: 0 3.5rem;
  }

  @media (max-width: 650px) {
    padding: 0;
  }

  @media (max-width: $mobile-size) {
    padding: 0 1rem;
  }

  @media (max-width: $index-card-size) {
    font-size: $font-size-base-mobile;

    h2 {
      font-size: $font-size-xxl-2-mobile;
    }
    h3 {
      font-size: $font-size-xl-mobile;
    }
    h4 {
      font-size: $font-size-l-mobile;
    }
  }
}

#editor :global {
  .ce-toolbar__plus {
    color: var(--icon-color-default);
    background-color: transparent;
  }
}

#editor :global {
  .cdx-quote {
    margin-top: $news-block-margin;
    border-radius: 10px;
    position: relative;
    margin-bottom: 14px;
    padding: 0;
    
    display: grid;
    grid-template-columns: 4px 1fr;
    grid-gap: 12px;
    gap: 12px;
    min-height: 0;
    min-width: 0;

    &::before {
      content: '';
      grid-row: span 2;

      margin: 6px 0;
      border-radius: 2px;
      border-left: 4px solid $red-primary;
    }
  }

  .cdx-quote__text,
  .cdx-quote__caption {
    border: none;
    box-shadow: none;
    margin: 0;
    padding: 0;
    border-radius: 0;
    font-size: $font-size-base;
    line-height: $line-height-l;
  }

  .cdx-quote__caption {
    font-weight: 600;

    @media (max-width: $index-card-size) {
      font-size: $font-size-base-mobile;
    }
  }

  .cdx-quote__text {
    height: 100%;
    min-height: 24px;
    width: 100%;
    overflow: hidden;

    @media (max-width: $index-card-size) {
      font-size: $font-size-base-mobile;
    }
  }
}
