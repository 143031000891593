@import "styles/variables.scss";
.Root {
  width: 100%;
  position: relative;
}

.Slider {
  width: 100%;

  -webkit-appearance: none;
  appearance: none;
  height: 5px;
  border-radius: 0px 0px 7px 7px;
  cursor: pointer;

  &::-webkit-slider-thumb {
    -webkit-appearance: none;
    appearance: none;
    width: 28px;
    height: 28px;
    background: url(./button.svg) no-repeat center;
    box-shadow: none;
  }
  &::-moz-range-thumb {
    border: none;
    border-radius: 0;
    width: 28px;
    height: 28px;
    background: url(./button.svg) no-repeat center;
    box-shadow: none;
  }
}