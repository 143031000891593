@import "styles/variables.scss";
.Button {
  display: flex;
  align-items: center;
  justify-content: center;
  width: fit-content;
  gap: 12px;
  height: 2.5rem;
  padding: 0 14px;

  white-space: nowrap;
  text-transform: uppercase;
  font-size: 0.8125rem;
  font-weight: 600;
  line-height: 150%;

  border: none;
  border-radius: 0.5rem;
  cursor: pointer;
}

.BigButton {
  font-size: 14px;
  height: 3rem;
  padding: 0 24px;
}

.SmallButton {
  font-size: 0.75rem;
  line-height: 110%;
  height: 1.25rem;
}

.PrimaryButton {
  color: $white-color;
  background: $blue-primary;

  &:hover {
    background: $blue-hover;
  }

  &:active {
    background: $blue-click;
  }

  &:disabled {
    background: $blue-disabled;
    pointer-events: none;
  }
}

.PrimaryOutlineButton {
  color: $blue-primary;
  background: $white-color;
  border: 1px solid $blue-primary;

  &:hover {
    color: $blue-hover;
    border-color: $blue-hover;
  }

  &:active {
    color: $blue-click;
    background: $blue-click-bg;
    border-color: $blue-click;
  }

  &:disabled {
    color: $blue-click;
    border-color: $blue-click;
    pointer-events: none;
  }
}

.PrimaryFilled {
  background: rgba($blue-primary, 0.1);

  &:hover {
    background: rgba($blue-primary, 0.15);
  }

  &:active {
    background: rgba($blue-primary, 0.2);
  }

  &:disabled {
    background: rgba($blue-primary, 0.1);
    pointer-events: none;
  }
}

.SecondaryButton {
  color: $white-color;
  background: $red-primary;

  &:hover {
    background: $red-hover;
  }

  &:active {
    background: $red-click;
  }

  &:disabled {
    background: $red-disabled;
    pointer-events: none;
  }
}

.SecondaryOutlineButton {
  color: $red-primary;
  background: $white-color;
  border: 1px solid $red-primary;

  &:hover {
    color: $white-color;
    background: $red-primary;
    border-color: $red-primary;
  }

  &:active {
    color: $white-color;
    background: $red-click;
    border-color: $red-click;
  }

  &:disabled {
    color: $red-click;
    border-color: $red-click;
    pointer-events: none;
  }
}

.SecondaryFilled {
  color: $black-primary;
  border-color: $grey-primary-4;

  &:hover {
    color: $black-primary;
    background: $white-color;
    border-color: $grey-primary-4;
  }

  &:active {
    color: $black-primary;
    background: $grey-primary-8;
    border-color: $grey-primary-4;
  }

  &:disabled {
    color: $black-primary;
    background: $grey-primary-8;
    border-color: $grey-primary-4;
    pointer-events: none;
  }
}

.Relative {
  width: 100%;
}

.InvertButton {
  color: $blue-primary;
  background: rgba($blue-primary, 0.1);
  border: 1px solid transparent;
  font-weight: 500;

  &:hover {
    background: rgba($blue-primary, 0.15);
  }

  &:active {
    background: rgba($blue-primary, 0.2);
  }

  &:disabled {
    opacity: 0.5;
    pointer-events: none;
  }
}

.EmptyButton {
  color: $blue-primary;
  background: $white-color;
  border: 1px solid $blue-primary;
  font-weight: 500;

  &:hover {
    border: 1px solid $blue-hover;
  }

  &:active {
    background: rgba($blue-primary, 0.05);
    border: 1px solid $blue-hover;
  }

  &:disabled {
    border: 1px solid $blue-disabled;
    pointer-events: none;
  }
}

.Full {
  width: 100%;
}
