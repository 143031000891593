@import "styles/variables.scss";
@import 'styles/mixsins';

.Root {
  all: unset;
  cursor: pointer;
  color: var(--font-color-interactive-secondary);
  display: flex;
  align-items: center;
  gap: 6px;
  
  background-color: var(--background-color-icons);
  padding:3px 6px;
  border-radius: 11px;
  
  transition: $transition;
  
  &:hover {
    background-color: var(--icon-color-hover);
  }
  
  &:focus {
    border: none;
  }
  
  svg {
    color: var(--background-color-tertiary-icons);
    fill: var(--background-color-tertiary-icons);
  }
  
  &.Active {
    background-color: rgba($blue-hover, 0.9);
    color: $white-color;
  }
}

.LikesCount {
  font-size: $font-size-s;
  font-weight: 600;
  line-height: 1;
  vertical-align: baseline;
}

.Button {
  all: unset;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  
  &:hover {
    background-color: rgba(var(--background-color-tertiary-icons), 0.1);
  }
  
  svg {
    color: var(--background-color-tertiary-icons);
  }
}

.ButtonActive {
  svg {
    fill: var(--background-color-tertiary-icons);
    
  }
}
