@import "styles/variables.scss";
.Root {
  div {
    border: none;
    outline: none;
    color: $white-color;
    width: 104px;

    &::after {
      background: url('../assets/icons/16/arrow-down-white.svg') no-repeat center;
      right: 9px;
    }

    ul {
      margin-top: 14px;
    }

    ul > li > div {
      color: var(--font-color-primary);
    }
  }
}
