@import "styles/variables.scss";
@import 'styles/mixsins';

.Root {
  position: relative;
  width: 100%;
}

.InputWrapper {
  position: relative;
  width: 100%;
}

.Input {
  @include FormText;
  height: 3rem;

  &::placeholder {
    color: var(--font-color-secondary);
  }
}

.Input[type="number"]::-webkit-outer-spin-button,
.Input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.Input[type="number"] {
  -moz-appearance: textfield; /* Для Firefox */
}

.SpinnerButtonLeft {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  left: 0.5rem;
  top: 50%;
  width: 24px;
  height: 24px;
  transform: translateY(-50%);
  background: var(--background-color-icons-secondary);
  border: none;
  cursor: pointer;
  font-size: 1rem;
  line-height: 1;
  color: $grey-primary;
  padding: 0;

  &:disabled {
    color: $grey-primary-6;
    cursor: not-allowed;
  }
}

.SpinnerButtonRight {
  position: absolute;
  right: 0.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  background: var(--background-color-icons-secondary);
  top: 50%;
  width: 24px;
  height: 24px;
  transform: translateY(-50%);
  border: none;
  cursor: pointer;
  font-size: 1rem;
  line-height: 1;
  color: $grey-primary;
  padding: 0;

  &:disabled {
    color: $grey-primary-6;
    cursor: not-allowed;
  }
}

.InputError {
  composes: Input;
  border: 1px solid $red-primary;

  &:focus, &:focus-visible {
    border: 1px solid $red-primary;
  }
}

.Right {
  position: absolute;
  top: 0.75rem;
  right: 0.75rem;
}

.Error {
  @include formError;
}
