@import "styles/variables.scss";
.Checkbox {
  cursor: pointer;
  color: var(--font-color-primary);
}

.Error {
  padding-top: 0.25em;
  color: $red-primary;
  font-size: 0.75rem;
  line-height: 130%;
}
