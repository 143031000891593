@import "styles/variables.scss";
.Button {
  border: none;
  border-radius: 50%;
  padding: 8px;
  transition: 0.3s ease-in;
  width: 36px;
  height: 36px;
  cursor: pointer;

  svg {
    transition: 0.3s ease-in;
    fill: $black-primary;
    width: 18px;
    height: 18px;
  }

  &:hover {
    border: none;
    outline: none;
    background-color: rgba($blue-primary, 0.1);

    svg {
      fill: $blue-primary;
    }
  }

  &:active {
    border: none;
    background-color: transparent;
  }
}
