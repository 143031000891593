@import "styles/variables.scss";
:root {
  /* Typography */
  --font-color-primary: #{$black-primary};
  --font-color-secondary: #{$grey-primary-7};
  --font-color-tertiary: #{$black-color};
  --font-color-text: #{$black-color};
  --font-color-dropdown: #{$blue-primary};
  --font-color-switch: #{$grey-primary-4};
  --font-color-disabled: $grey-primary;
  --font-color-interactive: #{$black-primary};
  --font-color-interactive-secondary: #{$black-primary};
  --font-color-tagline: #{$grey-primary};
  --font-color-body: #{$grey-primary-3};
  --font-color-caption: #{$grey-primary};
  --font-color-caption2: #{$grey-primary-7};
  --font-color-hover: #{$blue-hover};
  --font-color-vote-button-secondary: #{$red-primary};
  --font-color-vote-button-panel: #{$white-color};

  /* Borders */
  --border-color-default: #{$dark-primary-4};
  --border-color-secondary: #{$grey-primary-6};
  --border-color-active: #{$black-primary};
  --border-color-dropdown: #{$blue-primary};
  --border-color-table: #{$grey-primary-4};
  --border-color-button-vote-live-panel-disabled: #{$blue-disabled};

  /* Backgrounds */
  --background-color-default: #{$background-color};
  --background-color-secondary: #{$white-color};
  --background-disabled-root: linear-gradient(180deg, #{$light-primary-2} 0%, #{$grey-primary-8} 100%);
  --background-color-select: #{$white-color};
  --background-color-select-active: #{$background-color};
  --background-color-card: #{$white-color};
  --background-color-tertiary: #{$light-primary-4};
  --background-color-disabled: #{$white-color};
  --background-color-switch: #{$grey-primary-6};
  --background-color-modal-panel: #{$white-color};
  --background-color-back-table: #{$grey-primary-8};
  --background-color-icons: #{$background-color};
  --background-color-tertiary-icons: #{$red-primary-2};
  --background-color-icons-secondary: #{$background-color};
  --background-color-opponent-card: #{$white-color};
  --background-color-header: #{$dark-primary-4};
  --background-color-tooltip: #{$grey-primary-3};
  --background-color-orders-panel: #{$grey-primary-8};
  --background-color-element-layout-panel: #{$light-primary};
  --background-color-element-score: #{$light-primary-4};
  --background-color-button: #{$white-color};
  --background-color-red-button-widgets: #{$white-color};
  --background-color-blue-button-widgets: #{$white-color};
  --background-color-button-widgets-vote-pari-hover: #{$red-primary};
  --background-color-button-vote-pari-panel: #{$red-primary};
  --background-color-button-vote-live-panel: #{$blue-primary};
  --background-color-button-widgets-vote-live-hover: #{$blue-primary};
  --background-color-button-vote-live-panel-disabled: #{$blue-disabled};
  --background-color-red-stats-widget: #{$red-primary};
  --background-color-blue-stats-widget: #{$blue-primary};

  /* Status Colors */
  --status-color-draft: rgba(40, 115, 219, 0.14);
  --status-color-moderating: rgba(255, 172, 74, 0.14);
  --status-color-rejected: rgba(219, 40, 40, 0.14);
  --status-color-review: rgba(24, 142, 36, 0.14);

  /* opponents */
  --background-color-opponents-left-card: #{$red-primary};
  --background-color-opponents-center-card: #{$purple-primary};
  --background-color-opponents-right-card: #{$blue-primary};

  /* Interactive */
  --interactive-color-primary: #{$light-primary-3};

  /* Icons */
  --icon-color-default: #{$black-primary};
  --icon-color-social-media: #{$grey-primary-10};
  --icon-color-hover: #{$grey-primary-5};

}

:root.dark {
  /* Typography */
  --font-color-primary: #{$grey-primary-9};
  --font-color-secondary: #{$grey-primary-10};
  --font-color-tertiary: #{$grey-primary-9};
  --font-color-text: #{$white-color};
  --font-color-dropdown: #{$grey-primary-9};
  --font-color-switch: #{$grey-primary-2};
  --font-color-interactive: #{$grey-primary-8};
  --font-color-interactive-secondary: #{$grey-primary-10};
  --font-color-disabled: $grey-primary-3;
  --font-color-tagline: #{$grey-primary-9};
  --font-color-caption: #{$grey-primary-10};
  --font-color-caption2: #{$grey-primary-9};
  --font-color-body: #{$grey-primary-5};
  --font-color-hover: #{$background-color};
  --font-color-vote-button-secondary: #{$white-color};
  --font-color-vote-button-panel: #{$red-primary};

  /* Borders */
  --border-color-default: #{$grey-primary-10};
  --border-color-secondary: #{$grey-primary-3};
  --border-color-dropdown: #{$grey-primary-10};
  --border-color-active: #{$grey-primary-8};
  --border-color-table: #{$grey-primary-10};
  --border-color-button-vote-live-panel-disabled: #{$blue-primary};

  /* Backgrounds */
  --background-color-default: #{$dark-primary};
  --background-color-card: #{$dark-primary-2};
  --background-color-switch: #{$grey-primary-11};
  --background-color-header: #{$dark-primary-3};
  --background-color-select: #{$dark-primary-5};
  --background-color-select-active: #{$grey-primary-10};
  --background-color-secondary: #{$dark-primary-5};
  --background-color-tertiary: #{$grey-primary-4};
  --background-color-disabled: #{$dark-primary-5};
  --background-color-modal-panel: #{$dark-primary-2};
  --background-color-element-score: #{$grey-primary-12};
  --background-color-icons: #{$grey-primary-11};
  --background-color-tertiary-icons: #{$red-primary-2};
  --background-color-icons-secondary: #{$grey-primary-11};
  --background-color-tooltip: #{$grey-primary-12};
  --background-disabled-root: linear-gradient(180deg, #{$grey-primary-13} 0%, #{$dark-primary-4} 100%);
  --background-color-back-table: #{$grey-primary-11};
  --background-color-orders-panel: #{$grey-primary-11};
  --background-color-element-layout-panel: #{$grey-primary-13};
  --background-color-button: #{$blue-primary};
  --background-color-red-button-widgets: #{$red-primary-3};
  --background-color-blue-button-widgets: #{$blue-primary-1};
  --background-color-button-widgets-vote-pari-hover: #{$red-primary-4};
  --background-color-button-vote-pari-panel: #{$red-primary-3};
  --background-color-button-vote-live-panel: #{$blue-primary-1};
  --background-color-button-widgets-vote-live-hover: #{$blue-primary-2};
  --background-color-button-vote-live-panel-disabled: #{$blue-primary-4};
  --background-color-red-stats-widget: #{$red-primary-5};
  --background-color-blue-stats-widget: #{$blue-primary-5};

  /* status */
  --status-color-draft: rgb(40, 115, 219, 0.64);
  --status-color-moderating: rgba(255, 172, 74, 0.64);
  --status-color-rejected: rgba(219, 40, 40, 0.64);
  --status-color-review: rgba(24, 142, 36, 0.64);

  /* opponents */
  --background-color-opponents-left-card: #{$red-primary-3};
  --background-color-opponents-center-card: #{$purple-primary-1};
  --background-color-opponents-right-card: #{$blue-primary-1};

  /* Interactive */
  --interactive-color-primary: #{$grey-primary-14};

  /* Icons */
  --icon-color-default: #{$grey-primary-9};
  --icon-color-social-media: #{$grey-primary-9};
  --icon-color-hover: #{$grey-primary-12};

}
