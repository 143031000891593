@import "styles/variables.scss";
.Toastify__toast-container {
  z-index: $toast-z-index;

  @media (max-width: $mobile-size) {
  }
}

.Toastify__toast {
  font-weight: 600;
  font-size: 16px;
  line-height: 150%;
  border-radius: 8px;
  color: $white-color;
  min-height: 60px;
}

.Toastify__toast-body {
  align-items: flex-start;
}

.Toastify__toast-icon {
  width: 24px;
  height: 24px;
  background: $white-color;
  border-radius: 12px;
}

.Toastify__toast--success {
  background-color: $toast-success;
}
.Toastify__toast--error {
  background-color: $toast-error;
}
.Toastify__toast--warning {
  background-color: $toast-warning;
}
.Toastify__toast--info {
  background-color: $toast-info;
}

.Toastify__close-button {
  & svg {
    fill: $white-color;
  }
}
