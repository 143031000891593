@import "styles/variables.scss";
.Header {
  width: 100%;
  border-bottom: 1px solid $grey-primary-6;
  padding-bottom: 0.875em;
  color: $grey-primary;
  font-size: 0.75rem;
  line-height: 110%;

  &:nth-child(even) {
    background-color: $grey-primary-8;
  }
}

.Row {
  width: 100%;
  display: flex;
  border-bottom: 1px solid $grey-primary-6;
  padding: 14px 0;

  &:nth-child(2n) {
    background: $grey-primary-8;
  }
}

.RowNoLine {
  composes: Row;
  border-bottom: none;
}

.Col {
  flex-grow: 1;
  margin-right: 2.5rem;
  display: flex;

  @media (max-width: $mobile-size) {
    margin-right: 1.125rem;
  }
}

.ColNoGrow {
  composes: Col;
  flex-grow: 0;
}
