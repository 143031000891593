@import "styles/variables.scss";
.Slider {
  margin-top: -12px;
  padding: 0 4px 0 0px;
}

.CustomInput {
  text-align: center;
  font-size: 16px;
  line-height: 24px;
  font-weight: 600;
  color: var(--font-color-primary);
  background-color: var(--background-color-secondary);
}
