@import "styles/variables.scss";
.Root {
  border: none;
  background-color: transparent;
  width: fit-content;
  display: flex;
  align-items: center;
  gap: 6.5px;
  padding: 6px;
  font-weight: 600;
  font-size: $font-size-s-2;
  line-height: $line-height-l;
  text-transform: uppercase;
  color: var(--font-color-primary);
  transition: $transition;
  cursor: pointer;

  &:hover {
    opacity: 0.8;
  }
}

.Icon {
  width: 14px;
  height: auto;
}
