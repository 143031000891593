@import "styles/variables.scss";
.Root {
  width: 100%;
  padding: 19px 24px 22px 24px;
  color: var(--font-color-primary);
  background: var(--background-color-card);
  border-radius: $border-radius-base;

  @media (max-width: $mobile-size) {
    padding: 20px 16px;
  }
}

.SmallRoot {
  padding: 11px 18px 18px 18px;

  @media (max-width: $mobile-size) {
    padding: 18px 16px 24px;
  }
}

.NoPaddingRoot {
  padding: 0;
}
