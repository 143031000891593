@import "styles/variables.scss";
.Root {
  position: relative;
  display: inline-block;
}

.Button {
  display: flex;
  align-items: center;
  border: 1px solid var(--border-color-dropdown);
  border-radius: 0.5rem;
  color: var(--font-color-dropdown);
  padding: 0.1875rem 0.5rem 0.3125rem;
  margin: 0;
  background-color: var(--background-color-secondary);
  font-weight: 600;
  font-size: 0.8125rem;
  line-height: 150%;
  text-transform: uppercase;
  cursor: pointer;

  svg {
    fill: var(--font-color-dropdown);
  }

  &:hover {
    border: 1px solid $blue-hover;
    color: $blue-hover;

    svg {
      fill: $blue-hover;
    }
  }

  &:active {
    border: 1px solid $blue-click;
    color: $blue-click;
    background-color: $blue-click-bg;

    svg {
      fill: $blue-click;
    }
  }

  &:disabled {
    border: 1px solid $blue-disabled;
    color: $blue-disabled;

    svg {
      fill: $blue-disabled;
    }
  }
}

.ButtonDisabled {
  composes: Button;

  cursor: inherit;
}

.Label {
  max-width: 100px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding-right: 5px;
}

.ArrowIcon {
  width: 16px;
  height: auto;
  margin-left: 3px;
  margin-top: 2px;
}

.List {
  position: absolute;
  background: var(--background-color-secondary);
  border: 1px solid $grey-primary-2;
  box-shadow: $list-item-shadow;
  color: var(--font-color-primary);
  border-radius: 0.5em;
  padding: 0.5em 0;
  z-index: $list-z-index;
  margin-top: 0.5em;
}

.Item {
  padding: 0.3125em 0.875em;
  white-space: nowrap;
  cursor: pointer;
}

.ItemActive {
  composes: Item;
  background: var(--background-color-select-active);
}
