// Sizes
$desktop-size: 1300px;
$tablet-size: 834px;
$mobile-size: 430px;
$index-card-size: 660px;
$page-content-size: 980px;
$widget-size-m: 280px;
$widget-size-l: 450px;

// Colors
$background-color: #f3f3f3;
$black-color: #000000;
$white-color: #ffffff;
$black-primary: #1e1e1e;
$purple-primary:#6F36CC;
$purple-primary-1:#6F36CC14;

//light
$light-primary: #f8f9fa;
$light-primary-2:#e9e9e9;
$light-primary-3: #f0f8ff;
$light-primary-4: #f0f0f0;

// Grey
$grey-primary: #999999;
$grey-primary-2: #e4e4e4;
$grey-primary-3: #404040;
$grey-primary-4: #bebebe;
$grey-primary-5: #cccccc;
$grey-primary-6: #eeeeee;
$grey-primary-7: #666666;
$grey-primary-8: #fbfbfb;
$grey-primary-9: #c9cccf;
$grey-primary-10: #767b7b;
$grey-primary-11: #333333;
$grey-primary-12: #3a3a3a;
$grey-primary-13: #2c2c2c;
$grey-primary-14: #233045;

// Blue
$blue-primary: #2873db;
$blue-primary-1: #2873db14;
$blue-primary-2: #2873DB33;
$blue-primary-3: #7A9ACF;
$blue-primary-4: #1E4373;
$blue-primary-5: #4978FF;
$blue-hover: #3880e4;
$blue-click: #1b5fbe;
$blue-click-bg: rgba($blue-click, 0.05);
$blue-disabled: #2873db80;

// Red
$red-primary: #db2828;
$red-primary-2: #fa5a6a;
$red-primary-3: #DB282814;
$red-primary-4: #DB282833;
$red-primary-5: #FF6969FF;
$red-hover: #ee3333;
$red-click: #cb1a1a;
$red-disabled: rgba($red-primary, 0.5);
$red-gradient: linear-gradient(270deg, $red-primary 0%, #ff6969 100%);

// Green
$win-color: #35c244;
$green-primary: #188e24;


// dark
$dark-primary: #161617;
$dark-primary-2: #232324;
$dark-primary-3: #26282b;
$dark-primary-4: #1e1e1e;
$dark-primary-5: #2b2b2e;
$dark-primary-6: #19191a;


// Toast
$toast-success: #33bb41;
$toast-error: #db2828;
$toast-warning: #ffac4a;
$toast-info: #3880e4;

// Amount
$positive-color: #00aa11;
$negative-color: #db2828;

// z-indexes
$list-z-index: 10;
$panel-z-index: 1000;
$toast-z-index: 99999;

// Font sizes
$font-size-xxl: 26px;
$font-size-xxl-2: 24px;
$font-size-xl: 22px;
$font-size-l: 20px;
$font-size-m: 18px;
$font-size-base: 16px;
$font-size-s: 14px;
$font-size-s-2: 13px;
$font-size-xs: 12px;

$font-size-xxl-mobile: 24px;
$font-size-xxl-2-mobile: 22px;
$font-size-xl-mobile: 20px;
$font-size-l-mobile: 18px;
$font-size-m-mobile: 16px;
$font-size-base-mobile: 14px;
$font-size-s-mobile: 12px;
$font-size-xs-mobile: 11px;

// Line height
$line-height-l: 150%;
$line-height-m: 130%;
$line-height-base: 120%;
$line-height-s: 110%;
$line-height-xs: 100%;

// Other
$transition: 0.3s ease-in-out;
$list-item-shadow: 0px 8px 24px rgba(0, 0, 0, 0.04);
$divider: 1px solid var(--border-color-secondary);
$border-radius-base: 10px;

// News sizes
$news-block-margin: 16px;
$news-block-padding: 24px;
$news-block-margin-mobile: 16px;
$news-max-width: 708px + ($news-block-margin * 2);
