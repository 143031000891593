@import "styles/variables.scss";
@import 'styles/mixsins';

.Button {
  @include newsFooterButton();
  height: unset;
}

.CommentsCount {
  font-size: $font-size-s;
  font-weight: 600;
  color: var(--font-color-primary);
}
