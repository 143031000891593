@mixin border($borderType: "border-bottom") {
  #{$borderType}: $divider;
}

@mixin labelM {
  margin: 0;
  color: var(--font-color-primary);
  font-size: $font-size-s;
  padding-top: 4px;
}

@mixin linkActions($textDecoration: "none") {
  transition: $transition;

  &:hover {
    transition: $transition;
    text-decoration: #{$textDecoration};
    color: $blue-hover;
  }

  &:active {
    color: $blue-click;
  }
}

// TODO: remove
@mixin newsQuotationMark($content: "") {
  position: absolute;
  font-size: 30px;
  font-weight: 500;
  color: $black-primary;
  content: $content;

  @media (max-width: $index-card-size) {
    display: none;
  }
}

@mixin tableHeader {
  font-size: $font-size-xs;
  line-height: $line-height-base;
  color: $grey-primary;
  font-weight: 400;
}

@mixin newsFooterButton($padding:0) {
  border: none;
  border-radius: 50%;
  padding: $padding;
  transition: $transition;
  background-color: transparent;

  &:hover {
    transition: background-color $transition;
    border: none;
    outline: none;
    background-color: rgba($blue-primary, 0.1);
  }

  &:active {
    border: none;
    background-color: transparent;
  }
}

@mixin FormText {
  width: 100%;
  border: 1px solid $grey-primary-5;
  border-radius: 0.5rem;
  color: var(--font-color-primary);
  padding: 0.75rem 0.875rem;
  background-color: var(--background-color-secondary);
  font-size: $font-size-base;
  line-height: $line-height-m;

  &:focus, &:focus-visible {
    outline: none;
    border-color: var(--border-color-active);
  }
}

@mixin formError {
  margin: 0;
  padding-top: 0.3em;
  color: $red-primary;
  font-size: $font-size-xs;
  line-height: $line-height-base;
}
