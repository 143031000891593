@import "styles/variables.scss";
.paragraph,
.headline,
.link,
.caption,
.caption2,
.caption3,
.body,
.body2,
.body3,
.modal-title,
.tagline {
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
}

.body {
  font-size: 18px;
  line-height: 27px;
  color: var(--font-color-body);
}

.body2 {
  font-size: 19px;
  line-height: 150%;
  color: $black-color;
}

.body3 {
  font-size: 22px;
  line-height: 150%;
  color: var(--font-color-primary);
}

.paragraph {
  font-size: $font-size-base;
  line-height: $line-height-base;
  color: var(--font-color-primary);
  transition: $transition;
}

.modal-title {
  font-size: 28px;
  line-height: 130%;
  color: var(--font-color-primary);
}

.headline {
  font-size: 12px;
  color: $grey-primary;
}

.link {
  color: $blue-primary;
  font-size: $font-size-base;
  line-height: $line-height-l;
  cursor: pointer;
  transition: $transition;

  &:hover {
    color: $blue-hover;
  }

  &:active {
    color: $blue-click;
  }
}

.caption {
  font-size: 14px;
  color: var(--font-color-caption);
  word-wrap: break-word;
}

.caption2 {
  font-size: 13px;
  color: var(--font-color-caption2);
}

.caption3 {
  font-size: 12px;
  color: $blue-primary;
}

.tagline {
  font-size: 11px;
  color: var(--font-color-tagline);
}

.black-primary {
  color: var(--font-color-primary);
}

.grey-primary {
  color: $grey-primary;
}

.grey-primary-3 {
  color: $grey-primary-3;
}

.grey-primary-4 {
  color: $grey-primary-4;
}

.grey-primary-7 {
  color: var(--font-color-caption2);
}

.positive-color {
  color: $positive-color;
}

.negative-color {
  color: $negative-color;
}

.blue-primary {
  color: $blue-primary;
}

.red-primary {
  color: $red-primary;
}

.green-primary {
  color: $green-primary;
}

.white-color {
  color: $white-color;
}

.Uppercase {
  text-transform: uppercase;
}
