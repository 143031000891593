@import "styles/variables.scss";
@import 'styles/mixsins';

.Root > .Button:first-child {
  @include newsFooterButton();
  height: unset;
}

.ButtonActive {
  svg {
    fill: $blue-primary;
  }
}
