@import "styles/variables.scss";
.Root {
  width: 100%;
  position: relative;
  display: inline-block;
  border: 1px solid $grey-primary-5;
  border-radius: 0.5rem;
}

.Select {
  width: 100%;
  height: 3rem;
  display: flex;
  color: var(--font-color-primary);
  padding: 0.75rem 0.875rem;
  justify-content: space-between;
  align-items: center;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  cursor: pointer;

  &::after {
    content: '';
    position: absolute;
    right: 16px;
    top: 16px;
    height: 16px;
    width: 16px;
    background-color: var(--icon-color-default);
    mask: url('../icons/arrow-down.svg') no-repeat center;
    -webkit-mask: url('../icons/arrow-down.svg') no-repeat center;
    mask-size: cover;
    -webkit-mask-size: cover;
  }

  svg:first-of-type {
    width: 25px;
  }
}

.Placeholder {
  color: $grey-primary;
}

.SelectedItemLabel {
  margin: 0;
  padding-right: 18px;
}

.Error {
  padding-top: 0.25em;
  color: $red-primary;
  font-size: 0.75rem;
  line-height: 130%;
}

.List {
  position: absolute;
  width: 100%;
  background: var(--background-color-select);
  border: 1px solid $grey-primary-2;
  box-shadow: $list-item-shadow;
  border-radius: 0.5em;
  padding: 0.5em 0;
  z-index: $list-z-index;
  margin-top: 0.5em;
}

.Item {
  padding: 0.3125em 0.875em;
  white-space: nowrap;
  color: var(--font-color-primary);
  cursor: pointer;
  background-color: transparent;

  svg {
    width: 19px;
  }
}

.ItemActive {
  background-color: var(--background-color-select-active);
}
