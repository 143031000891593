@import "styles/variables.scss";
.Input {
  border-radius: 6px;
  border: 1px solid $grey-primary;
  padding: 10px;
  font-size: $font-size-base;
  width: 100%;
  margin-top: $news-block-margin;
}

.InputError {
  background-color: rgba($red-primary, 0.3);
}

.InputLoading {
  background-image: linear-gradient(
                  to right,
                  rgba($white-color, 0) 0%,
                  rgba($blue-hover, 0.25) 20%,
                  rgba($white-color, 0) 30%
  );
  background-repeat: no-repeat;
  background-size: 200% 100%;
  animation: loadingAnimation 1.5s infinite ease-in-out;
}

@keyframes loadingAnimation {
  from {
    background-position: 60% 0;
  }
  to {
    background-position: -100% 0;
  }
}

.VideoWrapper {
  position: relative;
  width: 100%;
  padding-top: 56.25%; /* aspect ratio 16:9 */

  iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}

.Widget {
  margin-top: $news-block-margin;
}
