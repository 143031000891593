@import "styles/variables.scss";
.Link {
  color: $white-color
}

.Agree {
  align-self: flex-end;
  background-color: #ffac4a;
  border-color: $white-color;
  color: $white-color;
  
  transition: $transition;
  
  &:hover {
    border-color: $white-color;
    background-color: $white-color;
    color: #ffac4a;
  }
}
